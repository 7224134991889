import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE } from "../../services/api";
import sodennLogo from "../../assets/sodenn.png";
import styles from "./login.module.scss";

const Login = () => {
  const [identifier, setIdentifer] = useState("");
  const navigate = useNavigate();

const onSubmit = async () => {
    if (identifier) {
        try {
            let member = await fetch(
                `${API_BASE}/member/id/${identifier}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                  },
                }
              );
    
              member = await member.json();
              
              if (member) {
                navigate(`/carnet/${identifier}`);
              }
        } catch(e) {
            console.log("Error***", e)
        }
    }
}


  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={sodennLogo} className={styles.logo} alt="sodenn logo" />
      </div>
      <div className={styles.innerContainer}>
        <label className={styles.text}>Cédula (sin guion)</label>
        <input
          name="identifier"
          value={identifier}
          className={styles.input}
          onChange={(e) => setIdentifer(e.target.value)}
        />
        <button className={styles.button} onClick={onSubmit} >Iniciar</button>
        <div className={styles.privacyPolicy}>
        <a href="https://appsodenn.sociedadplus.com/privacy.html" target="_blank" rel="noreferrer">Privacy Policy - Política de privacidad</a>
        <hr/>
        <a href="https://appsodenn.sociedadplus.com/terms_and_condition.html" target="_blank" rel="noreferrer">Terms and condition - Termino y condiciones</a>
      </div>
      </div>
     
    </div>
  );
};

export default Login;

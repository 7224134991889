import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import Carnet from './screen/carnet';
import Login from './screen/login';
import AuthLayout from './layout/AuthLayout';


const app = createBrowserRouter(createRoutesFromElements(
  <Route
    path="/"
    element={<AuthLayout/>}
  >
    <Route
    index
    element={<Login/>}
    />
    <Route
      path="carnet/:identifier"
      element={<Carnet/>}
    />
  </Route>
))

export default app;

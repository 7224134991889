import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import QRCode from "react-qr-code";
import { API_BASE } from "../../services/api";
import sodennLogo from "../../assets/sodenn.png";
import styles from "./carnet.module.scss";

const Carnet = () => {
  const [loading, setIsLoading] = useState(false);
  const [member, setMember] = useState({
    id: "",
    fullName: "",
    identifier: "",
    photo: "",
  });

  let { identifier } = useParams(); //"02000004925";

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        console.log("API***0");
        if (identifier) {
          let member = await fetch(
            `${API_BASE}/member/id/${identifier}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              },
            }
          );

          member = await member.json();
          
          setMember({
            fullName: `${member.names} ${member.familyName}`,
            identifier: member.displayidentifier,
            id: member.id,
            photo: member.photo,
          });
        }
      } catch (e) {
        console.log("Error***", e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [identifier]);

  if (loading) {
    return(<p>Is Loading</p>)
  }
   
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={sodennLogo} className={styles.headerLogo} />
      </div>
      <div className={styles.innerContainer}>
        <img src={member.photo} className={styles.profilePhoto} />
        <h1 className={styles.fullName}>{member.fullName}</h1>
        <div>
          <h2 className={styles.labelText}>Cédula:</h2>
          <p className={styles.text}>{member.identifier}</p>
        </div>
        <div className={styles.qrContainer}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "50%", width: "100%" }}
            value={member.identifier}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
    </div>
  );
};

export default Carnet;
